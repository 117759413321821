import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        margin: '1rem',
        [theme.breakpoints.down('sm')]: {
            margin: 'auto',
            marginTop: '5rem',
        },
    },
}));

export default useStyles;
