import React from 'react';
import { useParams } from 'react-router-dom';

import AvailabilityCalendar from 'core/libs/core-ui/components/AvailabilityCalendar';

import useStyles from './ViajeroBooking.styles';

const ViajeroBooking = () => {
    const { id, code } = useParams();
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <AvailabilityCalendar
                experienceId={id}
                code={code}
                fullScreenMode={true}
            />
        </div>
    );
};

export default ViajeroBooking;
