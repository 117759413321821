import { node, string } from 'prop-types';
import clsx from 'clsx';

import AppBar from 'components/AppBar';
import Footer from 'components/Footer'
import { useLayout } from './hook';

const Layout = ({ children, contentClassName, showUtriperButtons = false, setMaxWidth = true }) => {
  const { classes, open, toggleMenu, saved } = useLayout();

  return (
    <div className={classes.wrapper}>
      <AppBar open={open} toggleMenu={toggleMenu} saved={saved} showUtriperButtons={showUtriperButtons} />
      {/* <Menu open={open} toggleMenu={toggleMenu} /> */}
      <main
        className={clsx(
          classes.content,
          open && classes.contentShift,
          setMaxWidth && classes.contentMaxWidth,
          contentClassName
        )}>
        {children}
      </main>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: node.isRequired,
  contentClassName: string,
};

Layout.displayName = 'Layout';

export default Layout;
