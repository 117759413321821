import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
    DelimitedNumericArrayParam,
    useQueryParam,
    withDefault,
    NumberParam,
    StringParam,
} from 'use-query-params';
import { Box, Button, Grid2 } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';

import { ExperienceService } from 'core/api/services/experiences';
import { useTranslation } from 'core/contexts/localization';
import { usePagging } from 'core/contexts/pagging';
import { useExperiences } from 'core/contexts/experience';
import { useFilters } from 'core/contexts/filter';
import CircularIndeterminate from 'core/libs/core-ui/components/Progress';
import ExperiencesList from 'core/libs/core-ui/components/ExperienceList';
import palette from 'core/libs/core-ui/theme/colors-palette';

import Layout from 'components/Layout';
import landingbg from 'assets/images/experiences/landingbg.jpg';

import DestinationsList from './DestinationsList';
import Header from './Header';
import SearchView from './SearchView';

const IntegerArrayDefaultParam = withDefault(
    DelimitedNumericArrayParam,
    undefined
);

const useStyles = makeStyles((theme) => ({
    experiencesGrid: {
        backgroundImage: `url(${landingbg})`,
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
    },
    experienceList: {
        [theme.breakpoints.down('xl')]: {
            margin: '2rem 1rem',
            padding: '0 1rem',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: '1440px',
            margin: '2rem auto',
            padding: '0 1rem',
        },
    },
    card: {
        justifyContent: 'center',
        marginTop: '10px',
        marginBottom: '10px',
        padding: '1rem',
    },
    loadMoreButton: {
        backgroundColor: palette.lightblue,
        color: 'white',
        margin: '0 0.5rem',
        '&:hover': {
            backgroundColor: palette.lightblue,
        },
    },
}));

const Landing = () => {
    const classes = useStyles();
    const { t: translate } = useTranslation();
    const { loading, experiences, getExperiences } = useExperiences();
    const { currentPage, nextPage, hasMorePages } = usePagging();
    const location = useLocation();
    const [URLModus, setURLModus] = useQueryParam(
        'moods',
        IntegerArrayDefaultParam
    );

    // piece of state used to decide whether to display locations cards on landing
    const [searchParams, setSearchParams] = useState('');
    const [URLRadius, setURLRadius] = useQueryParam('radius', NumberParam);
    const [URLLat, setURLLat] = useQueryParam('lat', NumberParam);
    const [URLLng, setURLLng] = useQueryParam('lng', NumberParam);
    const [URLLocation, setURLLocation] = useQueryParam(
        'location',
        StringParam
    );

    useEffect(() => {
        setSearchParams(location.search);
        return () => setSearchParams('');
    }, [location]);

    const {
        moduses,
        locationData,
        radius,
        setModuses,
        setRadius,
        setLocationData,
    } = useFilters();

    useEffect(() => {
        const initialModuses = URLModus?.map((mood) => parseInt(mood)) ?? [];
        const initialRadius = URLRadius ?? 10;
        const initialLat = URLLat ?? 0;
        const initialLng = URLLng ?? 0;
        const initialLocation = URLLocation ?? '';

        setModuses(initialModuses);
        setRadius(initialRadius);
        setLocationData({
            name: initialLocation,
            lat: initialLat,
            lng: initialLng,
        });
    }, []);

    useEffect(() => {
        if (locationData.lng && locationData.lng != null) {
            // resetCurrentPage();
            getExperiences({ locationData, moduses, radius }, 1, true);
        }
    }, [locationData, moduses, radius]);

    // if load more is pressed, then get next page with current filters
    useEffect(() => {
        // console.log('Getting page: ' + currentPage);
        if ((locationData.name || moduses.length > 0) && currentPage > 1) {
            // console.log('Getting page: ' + currentPage);
            getExperiences(
                { locationData, moduses, radius },
                currentPage,
                false
            );
        }
    }, [currentPage]);

    return (
        <Layout showUtriperButtons={true} setMaxWidth={false}>
            <Header />
            <Box className={classes.experiencesGrid}>
                <SearchView />
                {(searchParams === '') ? (
                    <DestinationsList className={classes.card} />
                ) : (
                    <Box className={classes.experienceList}>
                        <ExperiencesList
                            contractExperiences={experiences}
                            useTranslation={useTranslation}
                            experienceService={ExperienceService}
                        />
                    </Box>
                )}
                {(loading && searchParams !== '') && (
                    <CircularIndeterminate centered={true} />
                )}
                <Grid2 container justifyContent='center' style={{ padding: 10 }}>
                    <Grid2 item>
                        {hasMorePages && experiences.length > 0 ? (
                            <Button
                                startIcon={<AddBoxRoundedIcon />}
                                className={classes.loadMoreButton}
                                onClick={nextPage}>
                                {translate('pagging.loadMore')}
                            </Button>
                        ) : null}
                    </Grid2>
                </Grid2>
            </Box>
        </Layout >
    );
};
export default Landing;
